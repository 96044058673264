let checkSelectvalidate = function(select) {
    let error = "error" + select.getAttribute("id");
    if (document.querySelector("#" + error)) { document.querySelector("#" + error).remove(); }
    if (select.value != "0") {
        select.parentNode.classList.add("is--valid");
        select.parentNode.classList.remove("is--invalid");
        select.removeAttribute("aria-describedby");
    } else {
        select.parentNode.classList.remove("is--valid");
        select.parentNode.classList.add("is--invalid");
        select.setAttribute("aria-describedby", error);
        let elError = document.createElement("span");
        elError.setAttribute("id", error);
        elError.classList.add("form__input--error");
        elError.innerHTML = "Wybierz jedną z opcji";
        select.parentNode.after(elError);
    }
}


let checkInputValidate = function(input) {

    let error = "error" + input.getAttribute("id");
    if (input.parentNode.querySelector(".form__input--error")) { input.parentNode.querySelector(".form__input--error").remove(); }
    if (input.validity.valid) {
        input.parentNode.classList.add("is--valid");
        input.parentNode.classList.remove("is--invalid");

        input.removeAttribute("aria-describedby");
    } else {
        input.parentNode.classList.remove("is--valid");
        if (input.form.classList.contains("afterFirstValidate") || input.classList.contains("focusout")) {
            input.parentNode.classList.add("is--invalid");
            input.setAttribute("aria-describedby", error);
            let elError = document.createElement("span");
            elError.setAttribute("id", error);
            elError.classList.add("form__input--error");
            elError.innerHTML = input.validationMessage;
            input.parentNode.append(elError);
        }
    }
}


let checkBoxValidate = function(input) {
    let error = "error" + input.getAttribute("id");
    if (input.form.classList.contains("afterFirstValidate") || input.classList.contains("focusout")) {
        if (input.parentNode.querySelector(".form__input--error")) { input.parentNode.querySelector(".form__input--error").remove(); }
        if (input.validity.valid) {
            input.parentNode.classList.add("is--valid");
            input.parentNode.classList.remove("is--invalid")
            input.removeAttribute("aria-describedby");
        } else {
            input.parentNode.classList.remove("is--valid");
            input.parentNode.classList.add("is--invalid")
            input.setAttribute("aria-describedby", error);
            let elError = document.createElement("span");
            elError.setAttribute("id", error);
            elError.classList.add("form__input--error");
            elError.innerHTML = input.validationMessage;
            input.parentNode.prepend(elError);
        }
    }
}

let isEmpty = function(el) {
    if (el.value == "") {
        el.parentNode.classList.add("empty");
        el.parentNode.classList.remove("noempty");
    } else {
        el.parentNode.classList.remove("empty")
        el.parentNode.classList.add("noempty")
        if (!el.validity.valid) {
            el.parentNode.classList.add("noempty");
            el.parentNode.classList.remove("empty");
        }
    }
}


if (document.querySelector('select:required')) {
    let select = document.querySelectorAll("select:required");
    for (i = 0; i < select.length; ++i) {
        select[i].addEventListener('change', function(e) {
            (this.value == "0") ? this.classList.remove("js-selected"): this.classList.add("js-selected");
            checkSelectvalidate(this);

        });
    }
}

let inputsRequiredAll = document.querySelectorAll('.form__input textarea:required, .form__input input:required');
let inputsAll = document.querySelectorAll('.form__input textarea, .form__input input');
let inputsCheckBoxAll = document.querySelectorAll('.form__input textarea, .form__checkbox input');

// if (inputsRequiredAll) {
//     for (i = 0; i < inputsRequiredAll.length; ++i) {
//         inputsRequiredAll[i].addEventListener("input", function() {
//             checkInputValidate(this);
//         })
//     }
// }
if (inputsAll) {
    for (i = 0; i < inputsAll.length; ++i) {
        inputsAll[i].addEventListener("input", function() {
            checkInputValidate(this);
            isEmpty(this);
        });
    }
}

if (inputsAll) {
    for (i = 0; i < inputsAll.length; ++i) {
        inputsAll[i].addEventListener("focusout", function() {
      
            if (!this.classList.contains("focusout")) {
                this.classList.add("focusout")
            }
            checkInputValidate(this);
            isEmpty(this);
        });
    }
}

if (inputsCheckBoxAll) {
    for (i = 0; i < inputsCheckBoxAll.length; ++i) {
        inputsCheckBoxAll[i].addEventListener("input", function() {
            checkBoxValidate(this);
        });
    }
}

let formValidate = function(form, namefunction) {

    const $form = form;
    const $btnSubmitt = form.querySelector('button[type="submit"]');

    $btnSubmitt.addEventListener('click', function(e) {
        const inputsRequired = form.querySelectorAll(".form__input input:required, .form__input textarea:required, .form__password input");

        const checkBoxRequired = form.querySelectorAll(".form__checkbox input:required");
        const selectRequired = form.querySelectorAll(".form__select select:required");


        e.preventDefault();
        $form.classList.add("afterFirstValidate");

        for (i = 0; i < inputsRequired.length; ++i) {
            checkInputValidate(inputsRequired[i]);

        }
        for (i = 0; i < checkBoxRequired.length; ++i) {
            checkBoxValidate(checkBoxRequired[i]);
        }
        for (i = 0; i < selectRequired.length; ++i) {
            checkSelectvalidate(selectRequired[i]);
        }

        if (form.querySelector("#i_haslo_2") && form.querySelector("#i_haslo_2").value != form.querySelector("#i_haslo_1").value) {

            let input = form.querySelector("#i_haslo_1");
            let error = "error" + input.getAttribute("id");
            input.focus();
            input.parentNode.classList.add("is--invalid");
            input.parentNode.classList.remove("is--valid");
            input.setAttribute("aria-describedby", error);
            let elError = document.createElement("span");
            elError.setAttribute("id", error);
            elError.classList.add("form__input--error");
            elError.innerHTML = input.getAttribute("data-nts");
            input.parentNode.append(elError);

        }

        if (form.querySelectorAll(".is--invalid").length == 0) {


            if (namefunction == 1) {

                if (document.querySelector("#del-pay-question input[data-rodzaj='9999']") || document.querySelector("#del-pay-question input[data-rodzaj='7777']")) {
                    if (document.querySelector("#del-pay-question input[data-rodzaj='9999']").checked && !document.querySelector("#input-paczkomat").value.length) {
                        document.querySelector("#paczkomat-box button").click();
                    } else {
                        if (document.querySelector("#del-pay-question input[data-rodzaj='7777']")) {
                            if (document.querySelector("#del-pay-question input[data-rodzaj='7777']").checked && !document.querySelector("#input-ruch").value.length) {
                                document.querySelector("#ruch-box button").click();
                            } else {
                                form.submit();
                            }
                        }
                        form.submit();
                    }
                } else {
                    form.submit();
                }

            } else {
                e.preventDefault();
                namefunction(form);
            }


        } else {

            if (form.querySelector(".is--invalid")) {

                let firstInvalidEl = form.querySelector(".is--invalid");
                if (firstInvalidEl.querySelector("input")) {
                    firstInvalidEl.querySelector("input").focus();
                } else {
                    if (firstInvalidEl.querySelector("textarea")) {
                        firstInvalidEl.querySelector("textarea").focus();
                    } else {
                        if (firstInvalidEl.querySelector("select")) {
                            firstInvalidEl.querySelector("select").focus();
                        }

                    }
                }

            }

        }
    })
}


if (document.querySelector('.form--validate')) {
    let formToValidate = document.querySelectorAll(".form--validate");
    for (i = 0; i < formToValidate.length; ++i) {
        formValidate(formToValidate[i], 1);
    }
}




if (document.querySelector('.select-onsubmit')) {
    let selectOS = document.querySelectorAll(".select-onsubmit");
    for (i = 0; i < selectOS.length; ++i) {
        selectOS[i].addEventListener('change', function(e) {
            this.form.submit();
        });
    }
}


if (document.getElementById('i_kod_pocztowy')) {
    var regExpMask2 = IMask(
        document.getElementById('i_kod_pocztowy'), {
            mask: '00-000'
        });
}

if (document.getElementById('kod_pocztowy')) {
    var regExpMask2 = IMask(
        document.getElementById('kod_pocztowy'), {
            mask: '00-000'
        });
}
if (document.getElementById('wysylka_kod_pocztowy')) {
    var regExpMask2 = IMask(
        document.getElementById('wysylka_kod_pocztowy'), {
            mask: '00-000'
        });
}


function validate(evt) {
    if (evt.keyCode != 8) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        var regex = /[0-9\s\/+\/-]/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;

            if (theEvent.preventDefault)
                theEvent.preventDefault();
        }
    }
}

if (document.getElementById('i_telefon')) {
    document.getElementById('i_telefon').addEventListener("keypress", function(e) {
        validate(e);
    });
}
if (document.getElementById('telefon')) {
    document.getElementById('telefon').addEventListener("keypress", function(e) {
        validate(e);
    });
}

if (document.getElementById('nip')) {
    var regExpMask2 = IMask(
        document.getElementById('nip'), {
            mask: Number
        });
}
if (document.getElementById('i_nip')) {
    var regExpMask2 = IMask(
        document.getElementById('i_nip'), {
            mask: Number
        });
}




// if ($("textarea").length) {
//     let textarea = document.querySelector('textarea');

//     textarea.addEventListener('keydown', autosize);

//     function autosize() {
//         var el = this;
//         setTimeout(function() {
//             el.style.cssText = 'height:auto; padding:4px';
//             // for box-sizing other than "content-box" use:
//             // el.style.cssText = '-moz-box-sizing:content-box';
//             el.style.cssText = 'height:' + el.scrollHeight + 'px';
//         }, 0);
//     }
// }



function removeAttributes(element, ...attrs) {
    attrs.forEach(attr => { element.removeAttribute(attr) })
}

function setAttributes(el, attrs) {
    for (var key in attrs) {
        el.setAttribute(key, attrs[key]);
    }
}


//checkbox firma czy osoba prywatna
if (document.querySelector('.cart__customer')) {
    let cartCustomerInputs = document.querySelectorAll(".cart__customer input");
    let formCInputs = document.querySelectorAll(".form__c input");
    for (i = 0; i < cartCustomerInputs.length; ++i) {
        cartCustomerInputs[i].addEventListener("change", function() {
            if (this.getAttribute("id") == "customer-1") {
                removeAttributes(document.querySelector("#i_nip"), "pattern", "minlength");
                if (document.querySelector('#f-wysylka-nip')) {
                    removeAttributes(document.querySelector("#f-wysylka-nip"), "pattern", "minlength");
                }
                formCInputs.forEach(attr => {
                    attr.removeAttribute("required");
                    if (attr.closest(".is--invalid")) {
                        attr.closest(".is--invalid").classList.remove("is--invalid");
                        attr.removeAttribute("aria-describedby")
                        attr.parentNode.querySelector(".form__input--error").remove();
                    }
                    attr.parentNode.style.display = "none";
                })
            } else {
                formCInputs.forEach(attr => {
                    attr.setAttribute("required", "required");
                    attr.parentNode.style.display = "block";
                })
                setAttributes(document.querySelector("#i_nip"), { "minlength": "9", "pattern": "[0-9]+" })
                if (document.querySelector('#f-wysylka-nip')) {
                    setAttributes(document.querySelector("#f-wysylka-nip']"), { "minlength": "9", "pattern": "[0-9]+" })
                }
                if (document.querySelector('#i_regon')) {
                    document.querySelector('#i_regon').removeAttribute("required");
                }
            }
        })
    }
}




if (document.querySelector('.form__password')) {
    let wrapper = document.querySelectorAll(".form__password");
    for (i = 0; i < wrapper.length; ++i) {
        let btn = wrapper[i].querySelector("button");
        let input = wrapper[i].querySelector("input");
        btn.addEventListener('click', function(e) {
            e.preventDefault();
            if (input.getAttribute("type") == "password") {
                input.setAttribute("type", "text");
                btn.classList.add("show");
            } else {
                input.setAttribute("type", "password");
                btn.classList.remove("show");
            }
        });
    }
}




//generowanie hasla

if (document.querySelector("#generate__btn")) {
    document.querySelector("#generate__btn").addEventListener("click", function(e) {
        e.preventDefault();
        let generujHaslo = new XMLHttpRequest();
        generujHaslo.onreadystatechange = function() {
            if (generujHaslo.readyState === 4) {
                document.querySelector(".generate__score").classList.add("open")
                document.querySelector(".generate__password").innerHTML = generujHaslo.responseText;
                document.querySelector("#input-accept").setAttribute("data-password", generujHaslo.responseText);
            }
        };
        generujHaslo.open('GET', '/a/?a=generuj-haslo');
        generujHaslo.send();
    })
}


if (document.querySelector("#input-accept")) {
    document.querySelector("#input-accept").addEventListener("click", function(e) {
        e.preventDefault();
        let password = this.getAttribute('data-password');
        let inputs = document.querySelectorAll("[data-password]");
        for (i = 0; i < inputs.length; ++i) {
            inputs[i].value = password;
        }


    })
}





var http_requestForm = false;

function makeRequestForm(url = '/a/', method = 'GET', form, callbackF) {
    http_requestForm = false;
    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_requestForm = new XMLHttpRequest();
        if (http_requestForm.overrideMimeType) {
            http_requestForm.overrideMimeType('text/xml');
            // Przeczytaj o tym wierszu poniżej
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_requestForm = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_requestForm = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {}
        }
    }

    if (!http_requestForm) {
        alert('Nie mogę stworzyć instancji obiektu XMLHTTP');
        return false;
    }

    let dataForm = new FormData(form);
    http_requestForm.onreadystatechange = function() { alertContents(http_requestForm, callbackF, form) };
    http_requestForm.open(method, url, true);
    http_requestForm.send(dataForm);

}


function alertContents(http_requestForm, functionName, form) {
    try {
        if (http_requestForm.readyState == 4) {
            if (http_requestForm.status == 200) {
                functionName(http_requestForm.responseText, form);

            } else {
                console.log('Wystąpił problem z tym żądaniem.');
            }
        }
    } catch (e) {
        console.log('Złapany wyjątek: ' + e.description);
    }
}

function newsletterCallbackFunction2(data) {
    let dataJ = JSON.parse(data);
    let info = document.createElement("div");
    info.classList.add("alert");
    info.innerHTML = dataJ.komunikat;
    if (dataJ.status) {
        info.classList.add("alert-success");

    } else {
        info.classList.add("alert-danger");
    }
    document.querySelector('.form--newsletter').before(info);
    document.querySelector('.form--newsletter').style.display = "none";
}

function newsletterForm(form) {
    makeRequestForm('/a/', "POST", form, newsletterCallbackFunction2);
}

if (document.querySelector('.form--newsletter')) {
    let formToValidate = document.querySelectorAll('.form--newsletter');
    for (i = 0; i < formToValidate.length; ++i) {
        formValidate(formToValidate[i], newsletterForm);
    }
}


function formPostSendCallbackFunction(data, form) {
  
    let dataJ = JSON.parse(data);
    let info = document.createElement("div");
    info.classList.add("alert");
    info.innerHTML = dataJ.komunikat;
    if (dataJ.status) {
        info.classList.add("alert-success");

    } else {
        info.classList.add("alert-danger");
    }
    form.before(info);
    form.style.display = "none";
}

function formPostSend(form) {
    makeRequestForm('/a/', "POST", form, formPostSendCallbackFunction);
}


if (document.querySelector('.form--post')) {
    let formToValidate = document.querySelectorAll(".form--post");
    for (i = 0; i < formToValidate.length; ++i) {
        formValidate(formToValidate[i], formPostSend);
    }
}
